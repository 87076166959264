<template>
   <layout-page :classes="pageClasses" v-slot:default="slotProps">
     <section v-if="dataErrored">
      <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </section>
    <section v-else>
      <loading v-if="dataLoading"></loading>
      <template v-else>

     <HeadPage :rawTitle="page[0].title.rendered" :content="page[0].content.rendered" :image="page[0].fimg_url.full" />

     <section v-if="dataErroredPost">
       <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
     </section>
     <section v-else>
       <loading v-if="itLoadsPost"></loading>
       <template v-else>
      <Featured :title="renderTitle(page[0].acf.title_1)" :datapost="posts" :categories="categories" :bgimg="page[0].acf.img_1" />
        <div class="container-fluid bg__secondary py-lg-5">
          <div class="row justify-content-center py-4 py-lg-5" style="overflow:hidden;">
            <div class="col-12 col-lg-10" v-if="Array.isArray(Object.values(slotProps.categories)) && Object.values(slotProps.categories).length">
              <div class="row" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="limit">
                <Post
                  v-for="(i, index) in allposts"
                  :key="index"
                  :title="i.title.rendered"
                  :img="i.fimg_url.full"
                  :dummyimg="slotProps.options.dummy_image"
                  :excerpt="i.excerpt.rendered"
                  :date="i.date"
                  :id="i.id"
                  :cat="renderCatName(categories, i.categories[0]) "
                  cols="col-md-6"
                  data-aos="slide-up" data-aos-duration="1000"
                />
              </div>
            </div>
          </div>
        <div class="row">
          <div class="col-12 py-5">
            <button v-if="allposts.length < counter" class="bt bt__ghost bt__primary mx-auto" @click="loadMore()">Load more</button>
          </div>
        </div>
      </div>
       </template>
     </section>

  </template>
    </section>

    </layout-page>
</template>

<script>
import LayoutPage from "@/layouts/LayoutPage.vue";
import HeadPage from "@/components/template-parts/HeadPage.vue";
import Featured from "@/components/insights/Featured.vue";
import Loading from '@/components/Loading.vue';
import Post from "@/components/insights/Post.vue";
import infiniteScroll from 'vue-infinite-scroll'

export default {
components: {
    LayoutPage,HeadPage,Featured,Post,Loading
  },
    directives: {
    infiniteScroll
    },
   data() {
    return {
      fetchSlug: String,
      itLoads: false,
      dataLoading: true,
      dataErrored: false,
      itLoadsPost: true,
      dataErroredPost: false,
      itLoadsCat: false,
      page: [
        {
          title:{
            rendered: 'Page'
          }
        }
      ],
      posts:Object,
      categories:Object,
      refe: Array,
      pageTitle: Array,
      pageClasses: ["internal-page", this.$route.name],
      allCategories: Array,
      allposts: [],
    limit: 4,
    busy: false,
    counter: Number,
    indexPage: 1,
    };
  },
  head() {
    return {
      title: this.page[0]._yoast_wpseo_title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.page[0]._yoast_wpseo_metadesc,
        },
      ],
    };
  },
   methods: {
      fetchingData() {
        this.fetchSlug = this.$route.path;
        this.$axios
          .get( this.$axios.defaults.baseURL +'wp/v2/pages/?slug=' +this.fetchSlug )
          .then((response) => {
            this.page = response.data;
            this.itLoads = true
          })
          .catch((error) => {
          console.log(error);
          this.dataErrored = true
        }).finally(() => this.dataLoading = false);
      },
      fetchPost() {
        this.$axios
          .get( this.$axios.defaults.baseURL +'wp/v2/posts?per_page=100')
          .then((response) => {
            this.posts = response.data;
          })
          .catch((error) => {
          console.log(error);
          this.dataErroredPost = true
        }).finally(() => this.itLoadsPost = false);
      },
      fetchCategories() {
        this.$axios
          .get( this.$axios.defaults.baseURL +'wp/v2/categories?hide_empty=true')
          .then((response) => {
            this.categories = response.data;
            this.itLoadsCat = true;
          })
          .catch((error) => {
            console.log(error)
          })
      },
      renderTitle(stg){
        let inputstg = stg.split(" ");
        inputstg[0] = '<span class="tx__c-primary">'+inputstg[0]+'</span>';
        inputstg = inputstg.join(' ');
        return inputstg;
      },
      renderCatName(categories, theId){
       let found = categories.find(element => element.id === theId);
        return found.name;
      },
    loadMore() {
      
      this.busy = true;
      this.$axios
          .get(this.$axios.defaults.baseURL +'wp/v2/posts?per_page=100').then(response => {
            this.counter = response.data.length;
            
        const append = response.data.slice(
          this.allposts.length,
          this.allposts.length + this.limit
        );
        this.allposts = this.allposts.concat(append);
        this.busy = false;

      }).catch((error) => {
            console.log(error)
          })
          this.indexPage++;
    }
      
  },
  created() {
    this.loadMore();
    this.fetchingData();
    this.fetchPost();
    this.fetchCategories();
  },
  mounted(){
    
  },
}
</script>

<style>

</style>