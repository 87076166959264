<template>
  <div class="w-100">
    <div class="container-fluid py-3 py-lg-5 mt-lg-5">
      <div class="row pt-5">
        <div class="col-12">
          <h2
            class="text-uppercase tx__w-bold mx-auto text-center"
            v-if="title"
            v-html="title"
          ></h2>
        </div>
      </div>
    </div>
    <div class="container-fluid featured-insights text-white"  >
        <div class="row">
        <div class="col-12">
          <div class="row align-items-center">
            <div class="col-12 col-xl-10 summary d-flex align-items-center" :style="{ backgroundImage: 'url(' + bgimg + ')'}">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-8 ">
                        <div class="row align-items-center" >
                            <div class="col-12 col-lg-6 py-3 py-lg-0" v-if="datapost">
                                    <h4>{{firstPost.title.rendered}}</h4>
                                    <div class="w-100" v-html="firstPost.excerpt.rendered"></div>
                                    <router-link :to="{ path: '/post/'+firstPost.id }" class="link link--readmore link--readmore-white">Read more</router-link>
                                </div>
                                <div class="col-12 col-lg-6 py-3 py-lg-0" v-if="datapost">
                                    <ul class="m-0 list-unstyled insights-latest">
                                        <li class="mb-4" v-for="(i, index) in allPost" :key="index">
                                            <span class="font__primary text-uppercase tx__space-2">{{ renderCatName(categories, i.categories[0]) }}</span>
                                            <router-link :to="{ path: '/post/'+i.id }" class="link--white d-block" v-html="i.title.rendered"></router-link>
                                        </li>
                                    </ul>
                                </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-12 d-lg-none d-xl-block col-xl-2 bg__secondary mn-l-8 py-4 py-lg-5 z-index-2">
              <div class="w-100 p-1">
                <h4 class="text-center text-uppercase"><span class="tx__c-primary">By</span> Category</h4>
              <ul class="m-0 list-unstyled insights-categories-list py-5" v-if="categories">
                  <li v-for="(i, index) in categories" :key="index" class="pb-3">
                      <router-link :to="{ path: '/category/'+i.id }" class="bt bt__ghost bt__ghost-white mx-auto">{{i.name}}</router-link>
                  </li>
              </ul>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
    datapost: Array,
    categories: Array,
    title: String,
    bgimg: String
  },
  data(){
    return{
      firstPost: Array,
      allPost: Array
    }
  },
  methods:{
    
    getLatest(theObj){
      let allPost = theObj.filter(post => post.tags == 10);
      this.firstPost = allPost.shift();
      this.allPost = allPost;
    },
    renderCatName(categories, theId){
        let found = categories.find(element => element.id === theId);
        return found.name;
      },
  },
  created(){

    if(this.datapost != null){
      this.getLatest(this.datapost);
    }

  }
};
</script>

<style>
</style>